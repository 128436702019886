<div class="component-container">
  <div *ngIf="ticketResponse$ | async; let ticketResponse;" class="dashboard-container">
    <mat-card class="dashboard-card" *ngFor="let workbook of ticketResponse.workbooks.workbook" class="dashboard-card"
      (click)="onWorkbookClicked(workbook)">
      <mat-card-header>
        <mat-card-title>
          {{workbook.name}}
        </mat-card-title>
      </mat-card-header>
      <img mat-card-image class="image" [src]="workbook.previewImage" />
    </mat-card>
  </div>
  <div #spinner class="dashboard-container" *ngIf="isLoading$ | async">
    <mat-spinner></mat-spinner>
  </div>
  <div class="dashboard-container" *ngIf="isError$ | async">
    An error occurred when fetching the workbooks, please refresh and try again
  </div>
</div>
