import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject, takeUntil, tap } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  
  year: Number;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit(): void {
    const date = new Date();

    this.year = date.getFullYear();

    this.authService.userAccount$.pipe(
      tap(account => {
        if (account) {
          this.router.navigate(['user', 'dashboard']);
        }
      }),
      takeUntil(this.ngUnsubscribe$)
    ).subscribe();

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
  }

  login() {
    this.authService.login();
  }
}
