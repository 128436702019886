import { ProtectedResourceScopes } from "@azure/msal-angular";
import { environment } from "src/environments/environment";

export function GetProtectedResourcesMap(): Map<string, (string | ProtectedResourceScopes)[]> {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(
    environment.msalCredentials.protectedResources['analyticsPortalApi'].endpoint,
    environment.msalCredentials.protectedResources['analyticsPortalApi'].scopes
  );

  return protectedResourceMap;
}
