import { Environment } from "./environment.model";

// The list of file replacements can be found in `angular.json`.
export const environment: Environment = {
    production: true,
    msalCredentials: {
        clientId: '12406aaa-2ab4-47a5-bf58-b65e3ec530b7',
        cloudInstanceId: 'https://login.microsoftonline.com',
        tenantName: 'pantherxrarepharmacyuat.onmicrosoft.com',
        authority: 'https://pantherxrarepharmacyuat.b2clogin.com/pantherxrarepharmacyuat.onmicrosoft.com/',
        signInB2CAuthority: 'B2C_1_AnalyticsUAT_SignIn',
        forgottenPasswordB2CAuthority: 'B2C_1_AnalyticsUAT_ForgotPassword',
        knownAuthorities: [
            'pantherxrarepharmacyuat.b2clogin.com'
        ],
        protectedResources: {
            analyticsPortalApi: {
                endpoint: 'https://prx-analytics-portal-dev.azurewebsites.net/api/v1',
                scopes: ['https://pantherxrarepharmacyuat.onmicrosoft.com/a5a65cae-a01a-4f31-b885-835b27f15972/access_as_user'],
            },
        }
    },
    tableau: {
        trustedUrl: 'https://tableau-embedded-dev.pantherxrare.com/trusted',
    },
    api: {
        baseUrl: 'https://prx-analytics-portal-dev.azurewebsites.net/api/v1'
    }
};
