import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, catchError, map, Observable, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { TicketResponse } from '../models/ticket-response.model';
import { UserGroup } from '../models/user-groups.model';

@Injectable({
  providedIn: 'root',
})
export class TableauService {
  private readonly tableauDefaultSiteClaimName = 'extension_DefaultSite';
  private readonly trustedUrlEmbedParams =
    '?:embed=yes&:tabs=yes&:toolbar=yes&:showShareOptions=false&:customViews=no&:refresh=no&:subscriptions=no';

  ticket$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private snackbar: MatSnackBar
  ) {}

  fetchTableauTicket(): Observable<TicketResponse> {
    var username: string = this.authService.userAccount$.value!!.username;
    var defaultSite: string = this.getPropFromToken(
      this.tableauDefaultSiteClaimName
    );
    var body = {
      username: username,
      targetSite: defaultSite,
    };

    return this.httpClient
      .post<TicketResponse>(`${environment.api.baseUrl}/tableau/ticket`, body)
      .pipe(
        tap((resp) => this.ticket$.next(resp.ticket)),
        map((resp) => {
          resp.workbooks.workbook = resp.workbooks.workbook.map((workbook) => {
            workbook.previewImage = `data:image/png;base64,${workbook.previewImage}`;

            var trustedUrl = '';

            // Create the trusted URL for the workbook, this will be the URL we will be embedded on the workbook page
            if (defaultSite.toLocaleLowerCase() === 'default') {
              //
              trustedUrl =
                `${environment.tableau.trustedUrl}/${resp.ticket}/views/${workbook.contentUrl}` +
                `/${workbook.views[0].viewUrlName}${this.trustedUrlEmbedParams}`;
            } else {
              trustedUrl =
                `${environment.tableau.trustedUrl}/${resp.ticket}/t/${workbook.site}/views/${workbook.contentUrl}` +
                `/${workbook.views[0].viewUrlName}${this.trustedUrlEmbedParams}`;
            }

            workbook.trustedUrl = trustedUrl;

            return workbook;
          });

          return resp;
        }),
        catchError((err) => {
          this.snackbar.open(
            'An error occurred when fetching the workbooks, please refresh and try again',
            'Dismiss',
            { duration: 4500 }
          );
          throw err;
        })
      );
  }

  fetchUserGroups(): Observable<UserGroup[]> {
    var username: string = this.authService.userAccount$.value!!.username;
    var defaultSite: string = this.getPropFromToken(
      this.tableauDefaultSiteClaimName
    );

    return this.httpClient.get<UserGroup[]>(
      `${environment.api.baseUrl}/tableau/usergroups?username=${username}&targetSite=${defaultSite}`
    );
  }

  private getPropFromToken(propertyName: string): string {
    return this.authService.userAccount$.value?.idTokenClaims?.[propertyName] as string;
  }
}
