<div class="page-container">
  <div class="documentation-container">
    <h1>Documentation</h1>
    <mat-accordion *ngIf="(userGroups$ | async) as userGroups; else spinner">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Territory
        </mat-expansion-panel-header>
        <mat-action-row>
          <a mat-raised-button href="../../assets/docs/PRX_Analytic Field Portal_042023.pdf"
            target="_blank">Download</a>
        </mat-action-row>
      </mat-expansion-panel>
    </mat-accordion>
    <ng-template #spinner>
      <mat-spinner class="spinner"></mat-spinner>
    </ng-template>
  </div>

  <div class="faq-container">
    <h1>FAQ</h1>
    <app-faq-accordion [panels]="faqPanels">

    </app-faq-accordion>
  </div>
</div>