<mat-toolbar color="primary">
  <button mat-icon-button class="menu-btn" (click)="opened = !opened" *ngIf="(userAccount$ | async); let userAccount">
    <mat-icon *ngIf="!opened">menu</mat-icon>
    <mat-icon *ngIf="opened">close</mat-icon>
  </button>
  <span>Analytics Portal</span>
  <span class="fill-spacer"></span>
  <div *ngIf="(userAccount$ | async); let userAccount">
    <span class="username">
      <span>{{userAccount.name}}</span>
    </span>
    <span>
      <mat-icon class="profile-icon">account_circle</mat-icon>
    </span>
  </div>
</mat-toolbar>

<mat-drawer-container class="drawer-container" hasBackdrop="false" autosize>
  <mat-drawer #sidenav mode="side" opened="true" disableClose  *ngIf="(userAccount$ | async); let userAccount">
    <div class="sidenav-container">
      <mat-nav-list>
        <a mat-list-item disableRipple class="logo-container">
          <img class="logo" src="../assets/square_logo_image.png" />
        </a>
        <a mat-list-item [routerLink]="['user', 'dashboard']">
          <mat-icon mat-list-icon>dashboard</mat-icon>
          <div mat-line *ngIf="opened">
            Dashboard Access
          </div>
        </a>
        <a mat-list-item [routerLink]="['user', 'faq']">
          <mat-icon mat-list-icon>help</mat-icon>
          <div mat-line *ngIf="opened">
            FAQ & Documentation
          </div>
        </a>
        <a mat-list-item [routerLink]="['user', 'contact-us']">
          <mat-icon mat-list-icon>contact_page</mat-icon>
          <div mat-line *ngIf="opened">
            Contact Us
          </div>
        </a>
        <a mat-list-item (click)="onLogoutClicked()">
          <mat-icon mat-list-icon>logout</mat-icon>
          <div mat-line *ngIf="opened">
            Logout
          </div>
        </a>
      </mat-nav-list>
    </div>

  </mat-drawer>

  <div class="router-container">
    <div class="router">
      <router-outlet></router-outlet>
    </div>
  </div>
</mat-drawer-container>