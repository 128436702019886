import { Injectable, OnDestroy } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo, RedirectRequest } from '@azure/msal-browser';
import { BehaviorSubject, Subject, takeUntil, tap } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {
  userAccount$: BehaviorSubject<AccountInfo | null> = new BehaviorSubject<AccountInfo | null>(null);
  jwt$: BehaviorSubject<string> = new BehaviorSubject('');
  unsubscribe$: Subject<void> = new Subject();

  constructor(private msalService: MsalService) {

    this.msalService.handleRedirectObservable().pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(
      resp => {
        if (resp) {
          this.userAccount$.next(resp.account)
        }
      }
    );

    var accounts = this.msalService.instance.getAllAccounts();

    if (accounts.length) {
      this.userAccount$.next(accounts[0]);
    }

    this.msalService
      .acquireTokenSilent({ scopes: ["openid"], account: this.userAccount$.value!! }).pipe(
        tap(resp => this.jwt$.next(resp.idToken)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
  }

  login() {
    this.msalService.handleRedirectObservable().subscribe(
      resp => this.userAccount$.next(resp.account)
    );
    this.msalService.loginRedirect().subscribe();
  }

  forgotPassword() {
    let forgottenPasswordFlowRequest: RedirectRequest = {
      scopes: ['openid'],
      authority:
        environment.msalCredentials.authority +
        environment.msalCredentials.forgottenPasswordB2CAuthority,
    };

    this.msalService.loginRedirect(forgottenPasswordFlowRequest).subscribe();
  }

  logout() {
    this.msalService.logoutRedirect().subscribe();
  }
}
