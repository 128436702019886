import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { map, Observable, BehaviorSubject, tap, catchError, of } from 'rxjs';
import { TicketResponse, Workbook } from '../models/ticket-response.model';
import { TableauService } from '../tableau/tableau.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  ticketResponse$: Observable<TicketResponse>;
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  isError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private tableauService: TableauService,
    private router: Router,
    private domSanitizer: DomSanitizer
  ) {
    // Get Tableau Ticket for User
    this.ticketResponse$ = this.tableauService.fetchTableauTicket().pipe(
      map((resp) => {
        resp.workbooks.workbook.forEach((workbook) => {
          const workbookPreviewImageString = workbook.previewImage as string;

          workbook.previewImage = this.domSanitizer.bypassSecurityTrustUrl(
            workbookPreviewImageString
          );
        });

        return resp;
      }),
      tap((p) => this.isLoading$.next(false)),
      catchError(err => {
        this.isError$.next(true);
        this.isLoading$.next(false);
        return of();
      })
    );
  }

  onWorkbookClicked(workbook: Workbook) {
    this.router.navigate(['user', 'analytics'], { state: { workbook } });
  }
}
