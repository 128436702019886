import { Component, OnInit } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { FaqPanel } from '../models/faq-panel.model';
import { UserGroup } from '../models/user-groups.model';
import { TableauService } from '../tableau/tableau.service';
import faqPanels from './faq.json';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  userGroups$: Observable<UserGroup[]> = of([]);
  faqPanels: FaqPanel[];

  isNationalManager = false;
  isRegionalManager = false;
  isTerritoryManager = false;

  constructor(private tableauService: TableauService) {

  }

  ngOnInit(): void {
    this.faqPanels = faqPanels;

    var nationalManager = "national managers";
    var regionalManager = "regional managers";
    var territoryManager = "territory managers";

    this.userGroups$ = this.tableauService.fetchUserGroups().pipe(
      tap(groups => this.isNationalManager = groups.some(group => group.name.toLocaleLowerCase() == nationalManager)),
      tap(groups => this.isRegionalManager = groups.some(group => group.name.toLocaleLowerCase() == regionalManager)),
      tap(groups => this.isTerritoryManager = groups.some(group => group.name.toLocaleLowerCase() == territoryManager)),
    );
  }
}
