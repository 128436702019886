import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MsalInterceptor, MsalModule } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MaterialModule } from 'src/material/material.module';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { GetProtectedResourcesMap } from './auth/msal.config';
import { FaqComponent } from './faq/faq.component';
import { FaqAccordion } from './faq-accordion/faq-accordion.component';

@NgModule({
  declarations: [
    AppComponent,
    ContactUsComponent,
    LoginComponent,
    DashboardComponent,
    AnalyticsComponent,
    FaqComponent,
    FaqAccordion,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MsalModule.forRoot(new PublicClientApplication({ // MSAL Configuration
      auth: {
        clientId: environment.msalCredentials.clientId,
        redirectUri: window.location.origin,
        postLogoutRedirectUri: '/',
        authority: environment.msalCredentials.authority + environment.msalCredentials.signInB2CAuthority,
        knownAuthorities: environment.msalCredentials.knownAuthorities,
      },
      cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage,
        storeAuthStateInCookie: true, // set to true for IE 11
      },
      system: {
        loggerOptions: {
          loggerCallback: () => { },
          piiLoggingEnabled: false
        }
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
    }, {
      protectedResourceMap: GetProtectedResourcesMap(),
      interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
    }),
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
