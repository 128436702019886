import { Component, OnDestroy, OnInit } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AccountInfo, EventMessage, EventType } from '@azure/msal-browser';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';
import { AuthService } from './auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private ngUnsubscribe$: Subject<void> = new Subject<void>();
  title = 'AnalyticsPortal';
  opened = false;
  userAccount$: BehaviorSubject<AccountInfo | null> =
    new BehaviorSubject<AccountInfo | null>(null);

  constructor(
    private authService: AuthService,
    private broadcastService: MsalBroadcastService
  ) {}

  ngOnInit(): void {
    this.userAccount$ = this.authService.userAccount$;

    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE),
        takeUntil(this.ngUnsubscribe$)
      )
      .subscribe((msg) => {
        if (msg.error?.message) {
          // Check for forgot password error
          // Learn more about AAD error codes at https://docs.microsoft.com/en-us/azure/active-directory/develop/reference-aadsts-error-codes
          if (msg.error?.message.indexOf('AADB2C90118') > -1) {
            this.authService.forgotPassword();
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
  }

  login() {
    this.authService.login();
  }

  onLogoutClicked() {
    this.authService.logout();
  }
}
