import { Component, Input, OnInit } from '@angular/core';
import { FaqPanel } from '../models/faq-panel.model';

@Component({
  selector: 'app-faq-accordion',
  templateUrl: './faq-accordion.component.html',
  styleUrls: ['./faq-accordion.component.scss']
})
export class FaqAccordion implements OnInit {

  @Input() panels: FaqPanel[];
 
  constructor() { }

  ngOnInit(): void {
  }

}
