<div class="container">
  <mat-card class="card">
    <mat-card-title>
      Contact Us [Technical Questions Only]
    </mat-card-title>
    <mat-card-content>
      <iframe src="https://econsent.pantherxrare.com/analytics-form" width="700" height="500" scrolling="no"></iframe>
    </mat-card-content>
  </mat-card>
</div>
