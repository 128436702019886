<div class="page-container">
    <div class="login-form mat-elevation-z2">
        <div class="login-form-header">
            <span>
                <img class="login-logo" src="../../assets/square_logo_image_dark_theme.png" />
            </span>
            <h2 class="login-form-title">
                Analytics Portal
            </h2>
        </div>
        <div>
            Welcome to PANTHERx's Analytics Portal, click below to begin
        </div>
        <div class="login-btn-container">
            <button class=login-btn color="accent" mat-flat-button (click)="login()">Log In</button>
        </div>
        <div class="disclaimer">
            Copyright © {{year}} PANTHERx Rare, LLC. All rights reserved.
            <br />
            <br />
            PANTHERx RARE PHARMACY, 
            “REINVENTING PHARMACY, REVOLUTIONIZING SPECIALTY, REDEFINING CARE”, 
            and THE RARE DISEASE SP are registered service marks of PANTHERx Rare, LLC.
        </div>
    </div>
</div>