import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AnalyticsComponent } from './analytics/analytics.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FaqComponent } from './faq/faq.component';
import { IsNotLoggedInGuard } from './is-not-logged-in.guard';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [IsNotLoggedInGuard]
  },
  {
    path: 'user',
    canActivateChild: [MsalGuard],
    children: [
      {
        path: 'contact-us',
        component: ContactUsComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'analytics',
        component: AnalyticsComponent
      },
      {
        path: 'faq',
        component: FaqComponent
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'code', 
    component: LoginComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
