import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { Workbook } from '../models/ticket-response.model';
import { TableauService } from '../tableau/tableau.service';

@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrls: ['./analytics.component.scss']
})
export class AnalyticsComponent implements OnInit {
  loading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  workbook: BehaviorSubject<Workbook | null> = new BehaviorSubject<Workbook | null>(null);
  ticket$: Observable<string>;

  constructor(
    private router: Router,
    private tableauService: TableauService,
    private domSanitizer: DomSanitizer
  ) {
    var nav = this.router.getCurrentNavigation();

    if (nav && nav.extras && nav.extras.state && nav.extras.state['workbook']) {
      var workbook = nav.extras.state['workbook'];
      this.workbook.next(
        {
          ...workbook,
          previewImage: this.domSanitizer.bypassSecurityTrustUrl(workbook.previewImage),
          trustedUrl: this.domSanitizer.bypassSecurityTrustResourceUrl(workbook.trustedUrl)
        }
      );

    }

    this.ticket$ = this.tableauService.ticket$.asObservable();
  }

  ngOnInit(): void {

  }

  onIFrameLoaded() {
    this.loading$.next(false);
  }

}
